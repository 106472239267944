<template>
  <div id="eventos">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title text-primary">Eventos</h4>
            <div v-if="perfil == 1" class="mb-3 text-right">
              <button
                data-toggle="modal"
                data-target="#nuevo"
                class="btn btn-success btn-rounded btn-sm"
              >
                <i class="fa fa-plus-circle"></i> Nuevo evento
              </button>
              <button
                v-on:click="get_eventos_shop"
                data-toggle="modal"
                data-target="#importar"
                class="btn btn-success btn-rounded btn-sm ml-2"
              >
                <i class="fas fa-cloud-download-alt"></i> Importar evento
              </button>
              <button
                v-on:click="load_search = !load_search"
                class="btn btn-info btn-rounded btn-sm ml-2"
              >
                <i class="fa fa-search"></i> Buscar evento
              </button>
            </div>
            <transition v-if="load_search" appear name="slide-fade">
              <div class="mb-3 mt-2">
                <input
                  v-model="search"
                  v-on:keyup="search_evento"
                  type="text"
                  class="form-control my-form-control"
                  placeholder="Buscar evento por nombre..."
                />
              </div>
            </transition>
            <div class="table-responsive pt-3">
              <table class="table my_table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="text-center">#</th>
                    <th>Evento</th>
                    <th>Desde</th>
                    <th>Hasta</th>
                    <th class="text-center">Aforo</th>
                    <th class="text-center">Vendido</th>
                    <th class="text-center">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(evento, index_evento) in eventos"
                    v-bind:key="index_evento"
                  >
                    <td class="text-center">{{ evento.num }}.</td>
                    <td class="font-weight-bold">
                      <router-link
                        class="nav-link"
                        v-bind:to="'/evento/' + evento.id_evento"
                      >
                        {{ evento.evento }}
                      </router-link>
                    </td>
                    <td>{{ evento.fecha_desde }} {{ evento.hora_desde }}</td>
                    <td>{{ evento.fecha_hasta }} {{ evento.hora_hasta }}</td>
                    <td class="text-center">{{ evento.aforo }}</td>
                    <td class="text-center">{{ evento.vendido }}</td>
                    <td class="text-center">
                      <button
                        v-on:click="load_editar(index_evento)"
                        data-toggle="modal"
                        data-target="#editar"
                        class="btn btn-primary btn-xs"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        v-on:click="load_eliminar(index_evento)"
                        data-toggle="modal"
                        data-target="#eliminar"
                        class="btn btn-danger btn-xs"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-4 mb-4" v-html="paginate"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="importar">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-primary">Importar evento</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Busque y seleccione el evento a importar</label>
              <v-select
                v-model="evento_shop"
                label="evento"
                :options="eventos_shop"
              ></v-select>
            </div>
            <div class="text-right">
              <button v-on:click="importar" class="btn btn-success">
                <i class="fas fa-cloud-download-alt"></i> Importar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="nuevo">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form v-on:submit.prevent="set_evento">
            <div class="modal-header">
              <h5 class="modal-title text-primary">Nuevo evento</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <transition appear name="slide-fade">
                <div
                  v-if="msg_nuevo.type != null"
                  class="alert"
                  v-bind:class="{
                    'alert-success': msg_nuevo.type,
                    'alert-danger': !msg_nuevo.type,
                  }"
                >
                  <i v-if="msg_nuevo.type" class="fa fa-check-circle"></i>
                  <i
                    v-if="!msg_nuevo.type"
                    class="fa fa-exclamation-circle"
                  ></i>
                  {{ msg_nuevo.text }}
                </div>
              </transition>

              <div class="form-group">
                <label>Nombre del evento</label>
                <input
                  v-model="nuevo.evento"
                  type="text"
                  class="form-control my-form-control"
                  required
                />
              </div>

              <div class="form-group">
                <label>Fecha de inicio</label>
                <input
                  v-model="nuevo.fecha_desde"
                  type="date"
                  class="form-control my-form-control"
                  required
                />
              </div>

              <div class="form-group">
                <label>Hora de inicio</label>
                <input
                  v-model="nuevo.hora_desde"
                  type="time"
                  class="form-control my-form-control"
                  required
                />
              </div>

              <div class="form-group">
                <label>Fecha de cierre</label>
                <input
                  v-model="nuevo.fecha_hasta"
                  type="date"
                  class="form-control my-form-control"
                  required
                />
              </div>

              <div class="form-group">
                <label>Hora de cierre</label>
                <input
                  v-model="nuevo.hora_hasta"
                  type="time"
                  class="form-control my-form-control"
                  required
                />
              </div>

              <div class="form-group">
                <label>Aforo del evento</label>
                <input
                  v-model="nuevo.aforo"
                  type="text"
                  class="form-control my-form-control"
                  required
                />
              </div>

              <div class="form-group">
                <label>Ventas del evento</label>
                <input
                  v-model="nuevo.vendido"
                  type="text"
                  class="form-control my-form-control"
                  required
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark btn-rounded btn-sm light"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-success btn-sm btn-rounded">
                <i class="fa fa-save"></i> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="editar">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form v-on:submit.prevent="update_evento">
            <div class="modal-header">
              <h5 class="modal-title text-primary">Editar evento</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="loading_edit" class="text-center">
                <small class="text-muted"> Cargando evento...</small><br />
                <div class="spinner-border text-info" role="status">
                  <span class="sr-only">Cargando evento...</span>
                </div>
              </div>
              <div v-else>
                <transition appear name="slide-fade">
                  <div
                    v-if="msg_edit.type != null"
                    class="alert"
                    v-bind:class="{
                      'alert-success': msg_edit.type,
                      'alert-danger': !msg_edit.type,
                    }"
                  >
                    <i v-if="msg_edit.type" class="fa fa-check-circle"></i>
                    <i
                      v-if="!msg_edit.type"
                      class="fa fa-exclamation-circle"
                    ></i>
                    {{ msg_edit.text }}
                  </div>
                </transition>
                <div class="form-group">
                  <label>Nombre del evento</label>
                  <input
                    v-model="evento.evento"
                    type="text"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Tipo de lugar</label>
                  <select v-model="evento.id_tipo_lugar" class="form-control">
                    <option value="0">Por defecto</option>
                    <option
                      v-for="(tipo_lugar, index_tipo_lugar) in tipos_lugares"
                      v-bind:key="index_tipo_lugar"
                      v-bind:value="tipo_lugar.id_tipo_lugar"
                    >
                      {{ tipo_lugar.tipo_lugar }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Fecha de inicio</label>
                  <input
                    v-model="evento.fecha_desde"
                    type="date"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Hora de inicio</label>
                  <input
                    v-model="evento.hora_desde"
                    type="time"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Fecha de cierre</label>
                  <input
                    v-model="evento.fecha_hasta"
                    type="date"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Hora de cierre</label>
                  <input
                    v-model="evento.hora_hasta"
                    type="time"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Aforo del evento</label>
                  <input
                    v-model="evento.aforo"
                    type="text"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Ventas del evento</label>
                  <input
                    v-model="evento.vendido"
                    type="text"
                    class="form-control my-form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark btn-rounded btn-sm light"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-success btn-sm btn-rounded">
                <i class="fa fa-save"></i> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="eliminar">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form v-on:submit.prevent="delete_evento">
            <div class="modal-header">
              <h5 class="modal-title text-danger">Eliminar evento</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="msg_eliminar.type == null">
                <div class="text-danger">
                  <i class="fa fa-question-circle"></i> ¿Desea eliminar el
                  registro del evento <strong>{{ evento.evento }}</strong
                  >?
                </div>
                <div class="text-center mt-3">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm btn-rounded"
                  >
                    Sí
                  </button>
                  <button
                    v-on:click="evento = []"
                    type="button"
                    class="btn btn-outline-dark btn-rounded btn-sm light ml-1"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
              <div v-if="msg_eliminar.type && msg_eliminar.type != null">
                <div class="alert alert-info">
                  <i class="fa fa-check-circle"></i> El evento ha sido
                  eliminado.
                </div>
              </div>
              <div v-if="!msg_eliminar.type && msg_eliminar.type != null">
                <div class="alert alert-danger">
                  <i class="fa fa-exclamation-circle"></i>
                  {{ msg_eliminar.text }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import "vue-select/dist/vue-select.css";
export default {
  name: "Eventos",
  metaInfo: {
    title: "Eventos - Taqui",
  },
  data() {
    return {
      loading_eventos: false,
      loading_eventos_shop: false,
      eventos: [],
      eventos_shop: [],
      page: 1,
      search: null,
      paginate: null,
      msg: {
        type: null,
        text: null,
      },
      nuevo: {
        evento: null,
        fecha_desde: null,
        hora_desde: null,
        fecha_hasta: null,
        hora_hasta: null,
        aforo: 0,
        vendido: 0,
      },
      msg_nuevo: {
        type: null,
        text: null,
      },
      loading_edit: false,
      evento: [],
      msg_edit: {
        type: null,
        text: null,
      },
      loading_eliminar: true,
      msg_eliminar: {
        type: null,
        text: null,
      },
      load_search: false,
      evento_shop: null,
      tipos_lugares: [],
    };
  },
  props: {
    perfil: String,
  },
  created: function () {
    this.get_eventos();
    this.loading = false;
  },
  methods: {
    get_eventos: function () {
      this.loading_eventos = true;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      var url = null;
      if (this.perfil == 1) {
        url = this.$base_url + "eventos/eventos";
      } else {
        var id_usuario = session.usuario.id_usuario;
        url = this.$base_url + "eventos/eventos_usuario/" + id_usuario;
      }
      this.page = this.$route.params.page;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
        params: {
          page: this.page,
          search: this.search,
        },
      };
      axios
        .get(url, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.eventos = response.data.data;
            this.get_paginate(response.data.total_pages);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            this.loading_eventos = false;
          }
        })
        .catch()
        .finally();
    },
    get_paginate: function (tpages) {
      var page = this.page;

      if (page === undefined || page <= 0) {
        this.page = 1;
        page = this.page;
      }

      var adjacents = 4;

      var paginate = null;

      paginate = '<ul class="pagination justify-content-center text-center">';
      // previous label
      if (page == 1) {
        paginate +=
          "<li class='paginate_button page-item previous disabled'><a class='page-link disabled'> <i class='fa fa-chevron-left'></i> </a></li>";
      } else if (page == 2) {
        paginate +=
          "<li class='paginate_button page-item  previous'><a href='#/eventos/1' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-left'></i> </a></li>";
      } else {
        var page_ant = parseFloat(page) - 1;
        paginate +=
          "<li class='paginate_button page-item'><a href='#/eventos/" +
          page_ant +
          "' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-left'></i> </a></li>";
      }
      // first label
      if (page > parseFloat(adjacents) + 1) {
        paginate +=
          "<li class='paginate_button page-item'><a class='page-link' href='#/eventos/1'>1</a></li>";
      }
      // interval
      if (page > parseFloat(adjacents) + 2) {
        paginate += "<li class='paginate_button page-item'> <a> ... </a> </li>";
      }
      // pages
      var pmin =
        page > adjacents ? parseFloat(page) - parseFloat(adjacents) : 1;
      var pmax =
        page < parseFloat(tpages) - parseFloat(adjacents)
          ? parseFloat(page) + parseFloat(adjacents)
          : tpages;

      for (var i = pmin; i <= pmax; i++) {
        if (i == page) {
          paginate +=
            "<li class='paginate_button page-item active'><a href='#/eventos/" +
            i +
            "' class='page-link'>" +
            i +
            "</a></li>";
        } else if (i == 1) {
          paginate +=
            "<li class='paginate_button page-item'><a href='#/eventos/" +
            i +
            "' class='page-link' href='javascript:void(0);'>" +
            i +
            "</a></li>";
        } else {
          paginate +=
            "<li class='paginate_button page-item'><a href='#/eventos/" +
            i +
            "' class='page-link' href='javascript:void(0);'>" +
            i +
            "</a></li>";
        }
      }
      // interval
      if (page < parseFloat(tpages) - parseFloat(adjacents) - 1) {
        paginate += "<li class='paginate_button page-item'> <a> ... </a> </li>";
      }
      // last
      if (page < parseFloat(tpages) - parseFloat(adjacents)) {
        paginate +=
          "<li class='paginate_button page-item'><a  href='#/eventos/" +
          tpages +
          "' class='page-link' href='javascript:void(0);'>" +
          tpages +
          "</a></li>";
      }
      // next
      if (page < tpages) {
        var page_next = parseFloat(page) + 1;
        paginate +=
          "<li class='paginate_button page-item next'><a  href='#/eventos/" +
          page_next +
          "' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-right'></i> </a></li>";
      } else {
        paginate +=
          "<li class='paginate_button page-item next disabled'><a class='page-link disabled'> <i class='fa fa-chevron-right'></i> </a></li>";
      }
      paginate += "</ul>";
      this.paginate = paginate;
    },
    set_evento: function () {
      this.msg_nuevo = {
        type: null,
        msg: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        Token: token_session,
      };
      var params = {
        evento: this.nuevo.evento,
        fecha_desde: this.nuevo.fecha_desde,
        hora_desde: this.nuevo.hora_desde,
        fecha_hasta: this.nuevo.fecha_hasta,
        hora_hasta: this.nuevo.hora_hasta,
        aforo: this.nuevo.aforo,
        vendido: this.nuevo.vendido,
      };
      const vm = this;
      axios
        .post(this.$base_url + "eventos/set_evento", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg_nuevo = {
              type: true,
              text: "Evento registrado.",
            };
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Eventos",
              text: "Evento registrado.",
            });
            vm.nuevo = {
              evento: null,
              fecha_desde: null,
              hora_desde: null,
              fecha_hasta: null,
              hora_hasta: null,
            };
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            vm.get_eventos();
          } else {
            vm.msg_nuevo = {
              type: false,
              text: response.data.message,
            };
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Eventos",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.msg_nuevo = {
            type: false,
            text: error,
          };
          vm.$notify({
            group: "foo",
            type: "success",
            title: "Eventos",
            text: error,
          });
        });
    },
    load_editar: function (index) {
      this.loading_edit = true;
      this.evento = this.eventos[index];
      this.loading_edit = false;
      this.get_tipos_lugares();
    },
    get_tipos_lugares: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(this.$base_url + "eventos/tipos_lugares", config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.tipos_lugares = response.data.data;
          }
        })
        .catch()
        .finally();
    },
    update_evento: function () {
      this.msg_edit = {
        type: null,
        msg: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        Token: token_session,
      };
      var params = {
        id_evento: this.evento.id_evento,
        evento: this.evento.evento,
        id_tipo_lugar: this.evento.id_tipo_lugar,
        fecha_desde: this.evento.fecha_desde,
        fecha_hasta: this.evento.fecha_hasta,
        hora_desde: this.evento.hora_desde,
        hora_hasta: this.evento.hora_hasta,
        aforo: this.evento.aforo,
        vendido: this.evento.vendido,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/update_evento", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg_edit = {
              type: true,
              text: "Evento actualizado.",
            };
            vm.get_eventos();
          } else {
            vm.msg_edit = {
              type: false,
              text: response.data.message,
            };
            console.log(response);
          }
        })
        .catch((error) => {
          vm.msg_edit = {
            type: false,
            text: error,
          };
        });
    },
    load_eliminar: function (index) {
      this.msg_eliminar = {
        type: null,
        msg: null,
      };
      this.evento = this.eventos[index];
    },
    delete_evento: function () {
      this.msg_eliminar = {
        type: null,
        msg: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        Token: token_session,
      };
      var params = {
        id_evento: this.evento.id_evento,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/eliminar_evento", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg_eliminar = {
              type: true,
              text: "evento eliminado.",
            };
            vm.get_eventos();
          } else {
            vm.msg_eliminar = {
              type: false,
              text: response.data.message,
            };
            console.log(response);
          }
        })
        .catch((error) => {
          vm.msg_eliminar = {
            type: false,
            text: error,
          };
        });
    },
    search_evento: function () {
      if (this.search != null) {
        if (this.search.length >= 3) {
          this.page = 1;
          this.get_eventos();
        } else {
          if (this.search == "" || this.search.length == 0) {
            this.search = null;
            this.get_eventos();
          }
        }
      }
    },
    get_eventos_shop: function () {
      this.loading_eventos_shop = true;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
        params: {
          search: this.search,
        },
      };
      axios
        .get(this.$base_url + "eventos/eventos_shop", config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.eventos_shop = response.data.data;
            this.loading_eventos_shop = false;
          }
        })
        .catch()
        .finally();
    },
    importar: function () {
      this.msg_nuevo = {
        type: null,
        msg: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        evento: this.evento_shop,
      };
      const vm = this;
      axios
        .post(this.$base_url + "eventos/set_importar_evento", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg_nuevo = {
              type: true,
              text: "Evento importando.",
            };
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Eventos",
              text: response.data.message,
            });
            vm.nuevo = {
              evento: null,
              fecha_desde: null,
              hora_desde: null,
              fecha_hasta: null,
              hora_hasta: null,
            };
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            vm.get_eventos();
          } else {
            vm.msg_nuevo = {
              type: false,
              text: response.data.message,
            };
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Eventos",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.msg_nuevo = {
            type: false,
            text: error,
          };
          vm.$notify({
            group: "foo",
            type: "success",
            title: "Eventos",
            text: error,
          });
        });
    },
  },
  watch: {
    $route() {
      this.page = this.$route.params.page;
      this.get_eventos();
    },
  },
};
</script>